function Avatar({ imgSrc, name, shape }) {
  const formattedName = name.replace('\n', ' ');
  return (
    <svg class="avatar" role="img" width="140" height="130" viewBox="0 0 140 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="shape0">
        <path d="M135.737 60.5269C138.523 74.0368 135.994 84.7727 134.202 92.3696C131.881 102.217 130.45 108.288 125.916 114.109C113.45 130.109 85.3955 134.104 66.3808 125.744C56.2207 121.275 56.5375 116.752 42.4445 110.741C27.6577 104.435 22.5722 107.389 15.4383 101.863C1.52551 91.0726 4.0546 66.7723 4.39327 63.8948C7.63249 36.1938 31.9293 20.5749 41.2154 14.602C44.1051 12.7437 76.7649 -7.8345 91.5407 3.27205C96.2875 6.83617 95.856 11.1633 104.738 19.5013C111.905 26.2208 114.669 25.7358 120.388 30.8313C131.679 40.8806 134.579 54.9355 135.732 60.5269H135.737Z" />
        </clipPath>
        <clipPath id="shape1">
          <path d="M132.755 66.166C135.107 80.9718 136.5 89.7537 132.322 99.8368C127.405 111.693 117.986 117.613 113.144 120.656C99.1136 129.469 85.8756 129.034 62.5936 128.272C42.9287 127.629 37.9091 124.321 35.1237 121.823C29.6021 116.867 29.2626 111.864 24.0363 99.8317C15.567 80.3549 10.4244 79.6136 6.74827 69.4371C-0.29883 49.9396 6.31521 18.7416 25.3404 6.60554C45.6156 -6.32886 73.6023 6.45001 85.2654 11.7741C87.4307 12.7643 94.6205 16.2169 102.967 20.5819C111.948 25.2736 116.436 27.622 118.188 29.058C128.158 37.2333 130.265 50.484 132.755 66.166Z" />
        </clipPath>
        <clipPath id="shape2">
        <path d="M128.826 100.607C123.209 118.382 100.306 125.834 96.7963 126.977C71.0543 135.352 37.7835 126.11 19.7759 101.949C7.90456 86.0186 1.79188 62.4276 9.27679 42.7342C10.9558 38.3174 13.6228 31.3012 20.4502 25.9741C34.0782 15.3435 44.4896 26.8844 62.0893 17.7811C75.1508 11.0211 82.4098 -2.06064 95.0836 0.275866C95.1746 0.292724 95.2353 0.306211 95.3533 0.32644C111.638 3.49573 125.04 15.1277 130.472 30.8022C130.927 32.1104 132.683 36.7025 133.402 43.0006C134.757 54.936 131.122 60.5564 129.275 73.0481C126.77 89.9937 131.709 91.4839 128.826 100.607Z" />
        </clipPath>
        <clipPath id="shape3">
        <path d="M129.118 79.5817C130.584 101.205 131.935 121.191 121.524 128.166C112.31 134.339 104.568 123.161 78.0022 120.573C48.3213 117.681 35.7571 129.466 21.83 119.307C12.1864 112.269 9.8385 100.512 9.45482 98.3592C7.37033 86.78 12.8793 80.4636 17.2831 66.4219C26.1479 38.1325 14.5229 29.5254 23.3533 16.3199C34.1078 0.245323 62.5576 -3.74039 83.3165 3.41786C108.17 11.9849 118.455 35.2921 122.028 43.3953C127.503 55.8048 128.202 66.0841 129.112 79.5759L129.118 79.5817Z" />
        </clipPath>
      </defs>
      <image
        class="avatarClip"
        width="140" height="130"
        clip-path={`url(#shape${shape})`}
        preserveAspectRatio={true}
        href={imgSrc}
      >
        <desc>{formattedName} avatar</desc>
      </image>
    </svg>
  );
}

export default Avatar;
